import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取账单的计费项详情
 * @param {Object} data
 */
export function getFeeItemList (data) {
  return request(
    {
      url: '/ooh-bill/bill/feeItem/getFeeItemList',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
