<template>
  <div>
    <Row>
      <i-col span="24" class="text-right">
        <i-button type="success" class="m-r-5" size="small"  @click="handleExportExcel">导出账单</i-button>
      </i-col>
    </Row>
    <div class="p-b-10">
      <div class="workplatform-title">{{ billDetail.billCode }}</div>

      <Row>
        <i-col span="8">
          <span class="title">结算公司 </span>{{ billDetail.companyName }}
        </i-col>
        <i-col span="8">
          <span class="title">结算公司 </span
          >{{ billDetail.settlementCompanyName }}
        </i-col>
        <i-col span="8">
          <span class="title">账单类型 </span>{{ billDetail.billTypeName }}
        </i-col>
      </Row>
      <Row>
        <i-col span="8">
          <span class="title">账期 </span>{{ billDetail.billPeriod }}
        </i-col>
        <i-col span="8">
          <span class="title">状态 </span>{{ billDetail.billStatus }}
        </i-col>
      </Row>
      <Row>
        <i-col span="24">
          <span class="title">金额 </span
          ><span class="money">{{ formatMoney(billDetail.amount) }}</span>
        </i-col>
      </Row>
    </div>
    <div class="p-t-10">
      <div class="workplatform-title">账单明细</div>
      <template>
        <Row class="m-b-5">
          <i-col span="16">
            <Row :gutter="8">
              <i-col span="18">
                <i-input
                  type="text"
                  size="small"
                  v-model="feeitemQuery.keywords"
                  clearable
                  placeholder="关键字"
                ></i-input>
              </i-col>
              <i-col span="6">
                <Button
                  icon="ios-search"
                  type="primary"
                  size="small"
                  @click="searchfeeitems"
                >
                  搜索
                </Button>
              </i-col>
            </Row>
          </i-col>
        </Row>
        <Table
          stripe
          class="m-t-5"
          :max-height="500"
          :data="feeitempage"
          :columns="column"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="feeitemTotal"
            :page-size="feeitemQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
            :current="feeitemQuery.pageNumber"
            @on-change="feeitemChangePage"
          ></Page>
        </div>
      </template>
    </div>
    <Modal
      v-model="feeitemDetailModal"
      v-if="chkFeeitemDetail"
      width="800"
      :footer-hide="true"
      title="账单详情"
    >
    <i-col span="24">
          <span class="title">合同/工单编号 </span
          >{{ chkFeeitemDetail.code }}
        </i-col>
        <i-col span="24">
          <span class="title">业务类型 </span
          >{{ chkFeeitemDetail.feeTypeName }}
        </i-col>
        <i-col span="24">
          <span class="title">入账金额 </span
          >{{  formatMoney(chkFeeitemDetail.amount) }}
        </i-col>
        <i-col span="24">
          <span class="title">入账时间 </span
          >{{ chkFeeitemDetail.chargeTime }}
        </i-col>
        <i-col span="24">
          <span class="title">
             </span
          >
        </i-col>
      <Row v-if="billDetail.billType === 1">
        <i-col span="8">
          <span class="title">客户名称 </span
          >{{ chkFeeitemDetail.props.advertiserName }}
        </i-col>
        <i-col span="8">
          <span class="title">客户品牌 </span>{{ chkFeeitemDetail.props.brandName }}
        </i-col>
        <i-col span="8">
          <span class="title">合同档期 </span>{{ formatSchedule(chkFeeitemDetail.props.startDate,chkFeeitemDetail.props.endDate) }}
        </i-col>
        <i-col span="8">
          <span class="title">产品名称 </span>{{ chkFeeitemDetail.props.productName }}
        </i-col>
        <i-col span="8">
          <span class="title">单品名称 </span
          >{{ chkFeeitemDetail.props.productSkuName }}
        </i-col>
        <i-col span="8">
          <span class="title">合同金额 </span
          >{{ formatMoney(chkFeeitemDetail.props.contractAmount) }}
        </i-col>
      </Row>
      <Row v-else-if="billDetail.billType === 2">
        <i-col span="8">
          <span class="title">客户名称 </span>{{ chkFeeitemDetail.props.advertiserName }}
        </i-col>
        <i-col span="8">
          <span class="title">任务类型 </span
          >{{ chkFeeitemDetail.props.taskTypeName }}
        </i-col>
        <i-col span="8">
          <span class="title">完成时间 </span>{{ chkFeeitemDetail.props.taskFinishTime }}
        </i-col>

        <i-col span="8">
          <span class="title">资源位置 </span>{{ chkFeeitemDetail.props.assetName }} {{ chkFeeitemDetail.props.stationName }}
        </i-col>
        <i-col span="8">
          <span class="title">资源类型 </span>{{ chkFeeitemDetail.props.resourceTypeName }}
        </i-col>

        <i-col span="8">
          <span class="title">金额 </span>{{ formatMoney(chkFeeitemDetail.props.amount) }}
        </i-col>
      </Row>
    </Modal>
  </div>
</template>

<script>
import { getMonthBillOutline } from '@/api/bill/monthBill'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getFeeItemList } from '@/api/bill/feeItem'
import { downloadFileRequest } from '@/utils/download'
import { toMoney } from '@/utils/wnumb_own'

export default {
  components: {},
  props: {},
  data () {
    return {
      billDetail: {},
      feeitempage: [],

      feeitemTotal: 0,
      feeitemQuery: {
        pageSize: 15,
        pageNumber: 1,
        keywords: '',
        billId: 0
      },
      column: [
        {
          title: '合同/工单编号',
          key: 'code'
        },
        {
          title: '业务类型',
          key: 'feeTypeName'
        },

        {
          title: '金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '入账时间',
          key: 'chargeTime'
        },

        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.chkFeeitemDetail = params.row
                      this.feeitemDetailModal = true
                    }
                  }
                },
                '详情'
              )
              // h(
              //   'a',
              //   {
              //     style: { marginRight: '5px', color: '#ef4f4f' },
              //     on: {
              //       click: () => {
              //         this.removeItem(
              //           1,
              //           JSON.stringify([params.row.feeitemId])
              //         )
              //       }
              //     }
              //   },
              //   '变更历史'
              // )
            ])
          }
        }
      ],

      feeitemDetailModal: false,
      chkFeeitemDetail: null
    }
  },
  created () {
    this.feeitemQuery.billId = this.billId
    this.initBillDetail()
    this.searchfeeitems()
  },
  computed: {
    billId () {
      return this.$store.state.bill.monthBillId
    },
    beginUpdate () {
      return this.$store.state.bill.beginUpdate
    }
  },
  methods: {
    // 计费项分页变更
    feeitemChangePage (currentPage) {
      this.feeitemQuery.pageNumber = currentPage
      this.initbillList()
    },
    // 计费项页大小变更
    handlePageSizeChanged (pagesize) {
      this.feeitemQuery.pageNumber = 1
      this.feeitemQuery.pageSize = pagesize
      this.initbillList()
    },

    formatMoney (number) {
      return toMoney(number)
    },

    formatSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    // 加载结算单基本信息
    initBillDetail () {
      const that = this
      getMonthBillOutline({ billId: that.billId }).then((res) => {
        that.billDetail = res
      })
    },
    // 搜索计费项
    searchfeeitems () {
      this.feeitemQuery.pageNumber = 1
      this.feeitempage = []
      this.initbillList()
    },
    // 加载所有结算项(分页)
    initbillList () {
      const that = this
      getFeeItemList(that.feeitemQuery).then((res) => {
        that.feeitempage = res.list
        that.feeitemTotal = res.totalRow
      })
    },
    handleExportExcel () {
      const query = {
        billType: this.billDetail.billType,
        billIdJsonStr: JSON.stringify([this.billId])
      }
      // const query = Object.assign({}, this.query)
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-bill/bill/feeItem/exportBillDetailList',
        query,
        '月账单详情.xlsx'
      )
    }
  },
  watch: {
    billId (val) {
      this.initBillDetail()
    },
    beginUpdate (val) {
      this.initBillDetail()
      this.searchfeeitems()
    }
  }
}
</script>
